<template>
  <div
    :class="classes"
    class="bg-grey3 rounded flex items-center justify-center overflow-hidden"
  >
    <!-- @load="handleImageLoad" -->
    <img
      v-if="!isError && companyId"
      :id="companyId"
      ref="imageRef"
      :src="loadedLogo + '?' + sessionId"
      @error="onError"
      class="object-contain max-h-full max-w-full"
    />
  </div>
</template>

<script setup>
import { loadLogo } from "@global/helpers/image.js";
import { computed, ref, watch } from "vue";
import { v4 as uuidv4 } from "uuid";
const sessionId = uuidv4();
const props = defineProps({
  companyId: {
    type: String,
    default: ""
  },
  classes: {
    type: String,
    default: ""
  }
});
const isError = ref(false);
watch(
  () => props.companyId,
  (newId, oldId) => {
    if (newId !== oldId) {
      isError.value = false;
    }
  }
);
const loadedLogo = computed(() => {
  return loadLogo(props.companyId);
});
const onError = (e) => {
  isError.value = true;
};

// const imageRef = ref(null);

// const handleImageLoad = () => {
//   const image = imageRef.value;

//   // Check if the image is fully loaded
//   if (image.complete) {
//     const isImageFullWhite = checkIfImageFullWhite(image);

//     if (isImageFullWhite) {
//       console.log("The image is full white.");
//     } else {
//       console.log("The image is not full white.");
//     }
//   }
// };

// const checkIfImageFullWhite = (image) => {
//   const canvas = document.createElement("canvas");
//   const ctx = canvas.getContext("2d");

//   // Set canvas dimensions to match the image
//   canvas.width = image.width;
//   canvas.height = image.height;

//   // Draw the image on the canvas
//   ctx.drawImage(image, 0, 0, image.width, image.height);

//   // Iterate through each pixel and check if it's white
//   for (let y = 0; y < image.height; y++) {
//     for (let x = 0; x < image.width; x++) {
//       const pixel = ctx.getImageData(x, y, 1, 1).data;
//       // Check if the pixel is not white (assuming a small threshold for non-white)
//       if (pixel[0] < 255 || pixel[1] < 255 || pixel[2] < 255) {
//         return false; // Image is not full white
//       }
//     }
//   }

//   return true; // Image is full white
// };
</script>

<style lang="scss" scoped></style>
