<template>
  <div
    :class="['mtb-card', { 'cursor-pointer': !disabled }, props.size]"
    @click.stop="handleClick"
  >
    <!-- === Header ==== -->
    <section class="flex flex-col justify-end w-full h-12">
      <div class="flex flex-row items-start justify-between w-full">
        <!-- Title + Logo -->
        <div class="flex flex-row items-center justify-start gap-3 w-full">
          <!-- logo -->
          <slot name="logo">
            <base-logo
              v-if="props.companyId"
              classes="w-8 h-8"
              :companyId="props.companyId"
            />
          </slot>

          <!-- Title + sponsorship -->
          <div
            class="flex flex-col justify-center items-start w-[calc(100%-44px)]"
          >
            <div
              class="flex flex-row items-center justify-start gap-1.5 w-full"
            >
              <div
                class="text-base font-bold text-black truncate capitalize max-w-5/6 text-start"
              >
                {{ props.title }}
              </div>
              <slot name="icon-title"></slot>
              <!-- <base-vite-icon
                v-if="props.sponsored"
                name="checkbox-selected"
                classes="fill-secondary stroke-white w-4 h-4"
              /> -->
            </div>
            <span
              class="text-grey2 text-sm font-normal text-left truncate w-full"
              >{{ props.subTitle }}</span
            >
          </div>
        </div>
        <!-- New badge -->
        <div v-if="props.isNew" class="mtb-badge-new w-1/6">New</div>
      </div>
    </section>
    <!-- ==== Footer ==== -->
    <section class="flex flex-row items-center justify-between w-full h-7">
      <div
        v-if="props.chipConfig"
        class="mtb-chip"
        :class="[
          props.chipConfig?.chipClasses,
          props.ctaButtonConfig ? 'w-2/5' : 'w-auto max-w-[80%]'
        ]"
      >
        <base-vite-icon
          v-if="props.chipConfig"
          :name="props.chipConfig.icon"
          :classes="props.chipConfig.iconClasses"
        />
        <span class="truncate w-full text-left">{{
          props.chipConfig?.label
        }}</span>
      </div>
      <div
        v-else
        :class="[props.ctaButtonConfig ? 'w-2/5' : 'w-auto max-w-[80%]']"
      >
        <slot name="chip" />
      </div>
      <base-button
        class="w-2/5"
        v-if="props.ctaButtonConfig"
        :label="props.ctaButtonConfig.label"
        :text="props.ctaButtonConfig.text"
        :icon="props.ctaButtonConfig.icon"
        :iconClass="props.ctaButtonConfig.iconClasses"
        :severity="props.ctaButtonConfig.severity"
      />

      <base-vite-icon
        v-if="!disabled"
        class="w-1/5"
        name="arrow-short-right"
        classes="fill-none stroke-2 stroke-grey2 w-5 h-5"
        role="button"
      />
    </section>
  </div>
</template>

<script setup>
import { bus } from "@/main.js";
import { useRoute, useRouter } from "vue-router";
const router = useRouter();
const route = useRoute();
const props = defineProps({
  //TODO: default value now is only for test
  companyId: {
    type: String,
    required: true,
    default: ""
  },
  cardUniqueId: {
    type: String,
    default: ""
  },
  size: {
    type: String,
    default: "card-sm"
  },
  title: {
    type: String,
    required: true,
    default: ""
  },
  subTitle: {
    type: String,
    default: ""
  },
  // sponsored: {
  //   type: Boolean,
  //   default: false
  // },
  isNew: {
    type: Boolean,
    default: false
  },
  cardType: {
    type: String,
    default: "startup"
  },
  //TODO: replace default with null value(this is only for test)
  ctaButtonConfig: {
    type: Object,
    default: () => ({
      label: "Test",
      classes: "bg-data1-light",
      icon: "add",
      iconClasses: "fill-none stroke-primary stroke-2 w-4 h-4",
      text: true,
      severity: "primary"
    }),
    validator: (value) => {
      if (value) {
        return ["label", "classes", "icon", "iconClasses", "severity"].every(
          (key) => key in value
        );
      }
    }
  },
  //TODO: replace default with null value(this is only for test)
  chipConfig: {
    type: Object,
    default: () => null,
    validator: (value) => {
      if (value) {
        return ["label", "chipClasses", "icon", "iconClasses"].every(
          (key) => key in value
        );
      }
    }
  },
  forceRouting: {
    type: Object,
    default: () => null
  },
  target: {
    type: String,
    default: "_self"
  },
  eventEmit: {
    type: String,
    default: ""
  },
  forceOpenSidebar: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

function handleClick() {
  if (props.disabled) {
    return;
  }
  if (!props.forceRouting) {
    openSideBar();
  } else {
    if (props.forceOpenSidebar) {
      router.push(props.forceRouting);
      //apply the routing + the default action
      setTimeout(() => {
        openSideBar();
      }, 500);
    } else {
      const routeUrl = router.resolve({ ...props.forceRouting }).href;
      window.open(routeUrl, "_blank");
    }
  }
}
const openSideBar = () => {
  if (["startup", "corporate"].includes(props.cardType)) {
    bus.emit("open-account-detail-sidebar", {
      id: props.companyId,
      accountType: props.cardType
    });
  } else if (props.cardType === "challenge") {
    bus.emit(props.eventEmit, props.cardUniqueId);
  }
};
</script>

<style lang="scss" scoped></style>
