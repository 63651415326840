<template>
  <div class="h-full">
    <base-action-buttons
      v-if="!preview && !search"
      :numberOfFilters="numberOfFilters"
      @reload="reload"
      @showFilter="showFilterss"
      @share="openShareModal"
    />
    <div v-if="isLoading" class="mt-4 flex flex-col gap-y-2">
      <div
        v-for="index in preview ? 2 : 4"
        :key="index"
        class="flex w-full h-[85px]"
      >
        <Skeleton class="w-full h-full"></Skeleton>
      </div>
    </div>
    <div
      v-else-if="institutionList.length === 0"
      class="mt-4 flex flex-col gap-y-2"
    >
      <fallback-empty-state
        message="
        There are no Ecosystem Partners yet.
      "
      />
    </div>
    <div
      v-else
      class="h-[calc(100%-43px)] w-full flex flex-col justify-end items-center"
    >
      <DataTable
        ref="table"
        :sortField="sort.sortField"
        :sortOrder="sort.sortDirection"
        :value="institutionRef"
        :virtualScrollerOptions="
          props.preview || !isChromium || !isLargeScreen
            ? null
            : {
                itemSize: 85
              }
        "
        scrollHeight="flex"
        scrollable
        :rows="!props.preview && !isChromium ? numberOfCards : null"
        dataKey="id"
        v-model:filters="filters"
        filterDisplay="menu"
        @row-click="openSidebar"
        :paginator="!props.preview && !isChromium"
        :paginatorTemplate="
          !props.preview && !isChromium
            ? 'PrevPageLink CurrentPageReport NextPageLink'
            : null
        "
        :currentPageReportTemplate="
          !props.preview && !isChromium ? '{currentPage} of {totalPages}' : null
        "
      >
        <Column
          field="name"
          filterField="name.name"
          class="sm:max-w-[20%] w-full"
          frozen
          alignFrozen="left"
          :preview="preview || search"
        >
          <template #header v-if="!preview && !search">
            <base-table-filter-multiselect
              :filterModel="filters.name.value"
              :sortingModel="sort"
              field="name.name"
              staticLabel="Partner Name"
              :options="filters.name.options"
              @updateFilter="
                (value) => {
                  filters.name.value = value;
                }
              "
              @updateSort="
                (value) => {
                  sort = value ? value : preSort;
                }
              "
            />
          </template>
          <template #body="{ data }">
            <div class="flex flex-row items-center justify-start gap-4">
              <base-logo :companyId="data.name.index" classes="w-8 h-8" />
              <span
                class="font-bold text-lg text-black capitalize sm:truncate whitespace-normal sm:whitespace-nowrap sm:max-w-[500px]"
              >
                {{ data?.name?.name || "--" }}
              </span>
            </div>
          </template>
        </Column>
        <Column
          field="HQ_Country__c"
          filterField="HQ_Country__c.name"
          class="sm:w-[20%] w-full"
          :preview="preview || search"
        >
          <template #header v-if="!preview && !search">
            <base-table-filter-multiselect
              :filterModel="filters.HQ_Country__c.value"
              :sortingModel="sort"
              field="HQ_Country__c.name"
              staticLabel="Country"
              :options="filters.HQ_Country__c.options"
              @updateFilter="
                (value) => {
                  filters.HQ_Country__c.value = value;
                }
              "
              @updateSort="
                (value) => {
                  sort = value ? value : preSort;
                }
              "
            />
          </template>
          <template #body="{ data }">
            <div class="flex flex-row items-center justify-start gap-4">
              <country-flag
                v-if="data?.HQ_Country__c?.name"
                :country="formatFlag(data.HQ_Country__c.name)"
                size="normal"
                class="mb-0"
              />

              <p class="text-base">
                {{ data?.HQ_Country__c?.name || "--" }}
              </p>
            </div>
          </template>
        </Column>
        <Column
          field="partnership"
          filterField="partnership"
          class="sm:w-[20%] w-full"
          frozen
          alignFrozen="left"
          :preview="preview || search"
        >
          <template #header v-if="!preview && !search">
            <base-table-filter-multiselect
              :sortingModel="sort"
              field="partnership"
              staticLabel="# Companies in MTB Ecosystem"
              @updateSort="
                (value) => {
                  sort = value ? value : preSort;
                }
              "
            />
          </template>
          <template #body="{ data }">
            <div
              class="flex flex-row items-center justify-start sm:ms-6 ms-0 sm:gap-4 gap-2"
            >
              <span class="text-base font-bold sm:hidden inline-block"
                ># Companies In MTB Ecosystem:</span
              >
              <p class="text-base">
                {{ getSponsorshipNumber(data?.name?.index) }}
              </p>
            </div>
          </template>
        </Column>
        <Column
          class="sm:w-[2rem] w-full"
          :frozen="true"
          alignFrozen="right"
          :preview="preview || search"
        >
          <template #body>
            <span class="cursor-pointer flex justify-end">
              <base-vite-icon
                class="w-1/5"
                name="arrow-short-right"
                classes="fill-none stroke-2 stroke-grey2 w-5 h-5"
            /></span>
          </template>
        </Column>
        <template #empty
          >No partners match your filter criteria. Please adjust your filters
          and try again.</template
        >
      </DataTable>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, toRef } from "vue";
import { useAccountsStore } from "@global/stores/accounts-store.js";
import { useScreenHeight } from "@global/hooks/use-screen-height.js";
import { FilterMatchMode } from "primevue/api";
import CountryFlag from "vue-country-flag-next";
import { bus } from "@/main.js";
import { formatFlag } from "@/global/helpers/util.js";
import { makeUniqueArray } from "@global/helpers/array.js";
import { v4 as uuidv4 } from "uuid";
import { default as mapping } from "@share/mappingFilter.js";
import { useFilterUrlBind } from "../hooks/use-filter-url-bind.js";
const accountsStore = useAccountsStore();

const props = defineProps({
  institutionList: {
    type: Array,
    default: () => []
  },
  isLoading: {
    type: Boolean,
    default: true
  },
  preview: {
    type: Boolean,
    default: false
  },
  search: {
    type: Boolean,
    default: false
  }
});

const isLargeScreen = ref(window.innerWidth >= 640);

const isChromium = !!window.chrome;

const institutionRef = computed(() => {
  return props.institutionList.reduce((acc, partner) => {
    const formattedPartner = {
      ...partner,
      name: {
        name: partner.name,
        index: partner.id || partner.Id
      },
      HQ_Country__c: {
        name: partner.HQ_Country__c,
        index: partner.HQ_Country__c
      },
      id: partner.id || partner.Id,
      partnership: getSponsorshipNumber(partner.id || partner.Id) || 0
    };
    if (partner.public !== false) {
      acc.push(formattedPartner);
    }
    return acc;
  }, []);
});
const filters = ref({
  name: {
    value: null,
    matchMode: FilterMatchMode.IN,
    options: computed(() => {
      return makeUniqueArray(
        institutionRef.value.map((ele) => {
          return ele.name;
        }),
        (a, b) => a.index === b.index
      ).sort((a, b) =>
        a.name === b.name ? a.index - b.index : a.name?.localeCompare(b.name)
      );
    }),
    name: "name",
    modalLabel: "Partner Name",
    index: uuidv4(),
    filterType: "multiselect",
    filterUrlKey: "_name",
    parseFilterUrl: (data) => {
      return data.map((item) => {
        return institutionRef.value.find(({ id }) => id === item)?.name;
      });
    }
  },

  HQ_Country__c: {
    value: null,
    matchMode: FilterMatchMode.IN,
    options: computed(() => {
      return makeUniqueArray(
        institutionRef.value.map((ele) => {
          return ele.HQ_Country__c;
        }),
        (a, b) => a.index === b.index
      ).sort((a, b) =>
        a.name === b?.name ? a.index - b.index : a.name?.localeCompare(b.name)
      );
    }),
    name: "HQ_Country__c",
    modalLabel: "Country",
    index: uuidv4(),
    filterType: "multiselect",
    filterUrlKey: mapping.filterKeys.COUNTRY,
    parseFilterUrl: (data) => {
      return data.map((item) => {
        return {
          name: item,
          iindex: item
        };
      });
    }
  }
});

const isLoadingRef = computed(() => {
  return props.isLoading;
});
!props.search && useFilterUrlBind(filters, isLoadingRef);
const preSort = {
  sortField: "partnership",
  sortDirection: -1
};
const sort = ref(preSort);

const numberOfCards = useScreenHeight(85, 150);

// ============== FUNCTIONS =========
const openSidebar = (e) => {
  e.originalEvent.stopImmediatePropagation();
  bus.emit("open-account-detail-sidebar", {
    id: e.data.id,
    accountType: "institution"
  });
};

function getSponsorshipNumber(id) {
  const institution = accountsStore.getters.getInstituionList.find(
    (inst) => inst.id === id
  );
  return (
    (institution?.sponsorships?.corporates?.filter((ele) => ele.active)
      .length || 0) +
    (institution?.sponsorships?.startups?.filter((ele) => ele.active).length ||
      0)
  );
}
import { useFilterModal } from "@/global/hooks/use-filter-modal.js";
const { showFilters } = useFilterModal();
const numberOfFilters = ref(0);
import { useFilterLogic } from "@global/hooks/use-filter-logic.js";
const { openFilterModal, forceExport } = useFilterLogic(
  filters,
  showFilters,
  null,
  props.search
);

const showFilterss = () => {
  if (!props.preview && !props.search) openFilterModal();
};
import { getUrl } from "@global/helpers/url.js";
import { userShareModal } from "@global/hooks/use-share-modal.js";
const { showShareModal } = userShareModal();

const openShareModal = async () => {
  showShareModal({
    modalTitle: "Ecosystem Partner List",
    sharingUrl: getUrl("ecosystem-partners?_share=true"),
    pageName: "Ecosystem Partner List"
  });
};

//=============== HOOKS =============
onMounted(() => {
  setTimeout(() => {
    if (!props.preview && !props.search) forceExport();
  }, 100);
  bus.on("filter-applied", (data) => {
    if (!props.preview && !props.search) numberOfFilters.value = data;
  });
  accountsStore.actions.setInstitutionsList();
  accountsStore.actions.setSponsorshipsList();
});
onUnmounted(() => {
  bus.off("filter-applied");
});
function reload() {
  accountsStore.actions.setInstitutionsList();
  accountsStore.actions.setSponsorshipsList();
}
</script>

<style lang="scss" scoped></style>
